<template>
  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    multi-line
    bottom
  >
    <v-icon left>
      {{ snackbar.icon }}
    </v-icon>
    <template #action="{ attrs }">
      <div
        v-for="msg in snackbar.messages"
        :key="msg.code"
        class="mr-5"
      >
        {{ msg.message }}
      </div>
      <v-btn
        color="error"
        text
        v-bind="attrs"
        @click="snackbar.visible = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

import {
  ref,
} from '@vue/composition-api'
import { mdiCheck, mdiCancel } from '@mdi/js'

export default {
  name: 'VToast',
  setup() {
    const snackbar = ref({
      visible: false,
      color: 'info',
      messages: [{
        code: '',
        message: '',
      }],
      timeout: '3000',
      icon: mdiCheck,
    })

    const show = (messages, type) => {
      snackbar.value.messages = messages
      snackbar.value.color = type === 'error' ? 'error' : ''
      snackbar.value.visible = true
    }

    return {
      snackbar,
      show,
      icons: {
        mdiCheck,
        mdiCancel,
      },
    }
  },
}
</script>
