<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-card>
      <v-card-title>
        <h3 class="mb-10">
          Deposit Funds
        </h3>
        <v-spacer></v-spacer>
        <div>
          <v-img
            :src="config.app.fullLogo"
            max-height="40px"
            :alt="config.app.name"
            contain
          ></v-img>
        </div>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-chip-group>
          <v-chip
            v-for="a in amounts"
            :key="a"
            :class="model.amount == a ? 'selected' : ''"
            @click="onAmountClickedHandler(a)"
          >
            {{ a }} USD
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <v-card-text class="mb-10 mt-2">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="2"
          >
            <v-text-field
              v-model="model.amount"
              :rules="validateAmountRules"
              class="centered-input"
              outlined
              dense
              placeholder="Amount"
              hide-details="true"
              model-value="0.00"
              label="Amount"
              suffix="USD"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-container>
            <h3 class="mb-5">
              Please, click on the payment method of your preference
            </h3>
            <v-row
              v-for="channel in channels"
              :key="channel.channelCode"
              dense
            >
              <v-col sm="12">
                <h2>{{ channel.channelName }}</h2>
                <v-row class="mt-5 mb-5">
                  <v-col
                    v-for="paymentMethod in channel.paymentMethods"
                    :key="paymentMethod.paymentMethodId"
                    v-model="paymentMethodModel"
                    :rules="[validators.required]"
                    class="d-flex child-flex"
                    md="3"
                  >
                    <v-card
                      hover
                      outlined
                      :class="paymentMethodModel == paymentMethod ? 'selected' : ''"
                      @click="paymentMethodModel = paymentMethod"
                    >
                      <v-img
                        :src="imagePath + '/' + paymentMethod.imageName"
                        class="white--text align-end"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
    <!-- divider -->
    <v-divider></v-divider>

    <div class="pa-3">
      <!-- action buttons -->
      <v-card-text class="text-center">
        <v-btn
          color="primary"
          class="me-3 mt-3"
          x-large
          :disabled="!valid || paymentMethodModel === null"
          :loading="loading"
          @click="onPayClickedHandler"
        >
          Pay {{ formatCurrency(model.amount) }} USD {{ paymentMethodModel != null ? `with: ${paymentMethodModel.methodName}` : '' }}
        </v-btn>
      </v-card-text>
    </div>
    <v-toast ref="vtoast"></v-toast>
  </v-form>
</template>

<script>
import {
  onMounted, ref,
} from '@vue/composition-api'
import { mdiClose, mdiKeyOutline, mdiHelpCircleOutline } from '@mdi/js'
import {
  required,
  emailValidator,
  integerValidator,
} from '@core/utils/validation'
import axios from '@axios'
import themeConfig from '@themeConfig'
import {
  formatCurrency,
} from '@core/utils/filter'
import VToast from '@/components/VToast.vue'

export default {
  components: {
    VToast,
  },
  setup() {
    const amounts = ref([1, 5, 100, 250, 500, 1000])
    const vtoast = ref(null)
    const imagePath = ref(process.env.VUE_APP_AWS_S3_PAYMENT_METHODS)
    const config = ref(themeConfig)
    const { shopId } = JSON.parse(localStorage.getItem('userData'))
    const customer = JSON.parse(localStorage.getItem('account'))

    console.log(customer)

    const model = ref({
      shopId,
      customer,
      paymentMethod: {},
      amount: 0,
      currencyCode: 'USD',
    })

    const channels = ref([])
    const paymentMethodModel = ref(null)
    const loading = ref(false)
    const valid = ref(false)
    const form = ref()

    const fetchPaymentMethods = () => {
      loading.value = true

      console.log(model.value)
      axios.get('/processing/payment-links/payment-methods', { params: { shopId, countryCode: model.value.customer.country } })
        .then(pm => {
          channels.value = pm.data.channels
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')

          return error
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onPayClickedHandler = () => {
      loading.value = true

      model.value.paymentMethod = paymentMethodModel.value

      axios.post('/processing/payments', model.value)
        .then(response => {
          window.location.href = response.data
        })
        .catch(error => {
          vtoast.value.show(error.response.data.errors, 'error')

          return error
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchPaymentMethods()
    })

    const onAmountClickedHandler = amount => {
      model.value.amount = amount
    }

    const validateAmountRules = [
      v => !!v || 'This field is required',
      v => (v && v >= 1) || 'Loan should be above $ 100',
      v => (v && v <= 50000) || 'Max should not be above $ 50,000',
    ]

    return {
      amounts,
      loading,
      form,
      valid,
      vtoast,
      model,
      paymentMethodModel,
      channels,
      imagePath,
      config,
      validateAmountRules,
      formatCurrency,
      fetchPaymentMethods,
      onPayClickedHandler,
      onAmountClickedHandler,

      // validation
      validators: { required, emailValidator, integerValidator },
      icons: {
        mdiClose,
        mdiKeyOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

    <style lang="scss" scoped>
    .two-factor-auth {
      max-width: 25rem;
    }

    .security-character {
      position: absolute;
      bottom: -0.5rem;
    }

    .red--text{
      color: red;
    }

    .selected {
      border: 2px solid;
    }

    .centered-input{
      text-align: center!important;
      font-size: 18pt;
      font-weight: 600;
    }
    </style>
